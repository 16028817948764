<template>
  <div class="feedback-block">
    <div class="progress" v-if="!successMessage">
      <span :value="((currentStep + 1) * stepPercentage)" :style="'width:' + ((currentStep + 1) * stepPercentage) + '%'" />
    </div>
    <div v-for="(que, index) in questionSortList" :key="index">
      <div v-if="currentStep === index && !successMessage">
        <h2 class="feedback-title">
          {{ que.question_title }}
        </h2>
        <div v-if="que.type_question === rating" class="star-rating-wrap">
          <star-rating :active-star="formData[que.id]" v-model="formData[que.id]" @feedback="setFeedBack" />
        </div>
        <div v-if="que.type_question === radio" class="radio-blocks">
          <div v-for="(opt, ind) in radioOptions(que.options)" :key="`radio_opt_${opt.value_option_0}_${index}_${ind}`" class="radio">
            <input
              type="radio"
              :name="formData[que.id]"
              :value="opt[`value_option_${storeId}`] || opt[`value_option_0`] + '#' + opt.record_id"
              v-model="formData[que.id]"
              :id="`radio-${ind}`"
              @click="setFeedBack"
            >
            <label v-if="que.show_label == '1'" :for="`radio-${ind}`" class="radio-label">
              {{ opt[`value_option_${storeId}`] || opt[`value_option_0`] }}
            </label>
          </div>
        </div>
        <div v-if="que.type_question === radioWithImage" class="radio-icons radio-no">
          <div v-for="(opt, ind) in radioOptions(que.options)" :key="`radio_opt_${opt.value_option_0}_${index}_${ind}`" class="radio">
            <input
              type="radio"
              :name="formData[que.id]"
              :value="opt[`value_option_${storeId}`] || opt[`value_option_0`] + '#' + opt.record_id"
              v-model="formData[que.id]"
              :id="`radio-${ind}`"
              @click="setFeedBack"
            >
            <label :for="`radio-${ind}`" class="radio-label">
              <img :src="opt.image_field" height="35px" width="35px">
              <span v-if="que.show_label == '1'">{{ opt[`value_option_${storeId}`] || opt[`value_option_0`] }}</span>
            </label>
          </div>
        </div>
        <div v-if="que.type_question === inputText" class="tell-experiance-textarea">
          <textarea :id="`question_${index++}`" :name="formData[que.id]" v-model="formData[que.id]" />
        </div>
        <div v-if="que.type_question === checkbox" class="checkbox-icons">
          <div v-for="(opt, ind) in radioOptions(que.options)" :key="`radio_opt_${opt.value_option_0}_${index}_${ind}`" class="checkbox">
            <input
              type="checkbox"
              :name="formData[que.id]"
              :value="opt.record_id"
              :id="`radio-${ind}`"
              @click="setCheckbox($event, que)"
            >
            <label v-if="que.show_label == '1'" :for="`radio-${ind}`" class="checkbox-label">
              {{ opt[`value_option_${storeId}`] || opt[`value_option_0`] }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-wrap" v-if="!successMessage">
      <button class="back-btn" @click="prevStep" v-if="currentStep != 0">
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 6.5H2" stroke="#30B75F" stroke-width="2" />
          <path d="M7 1.5L2 6.5L7 11.5" stroke="#30B75F" stroke-width="2" />
        </svg>
        <span>{{ $t('Back') }}</span>
      </button>
      <button
        class="ahead-btn"
        @click="nextStep"
        v-if="currentStep != (questionList.length - 1)"
        :class="{ 'disable': isFeedback }"
      >
        <span>{{ $t('Next') }}</span>
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 6.5H14" stroke="white" stroke-width="2" />
          <path d="M9 1.5L14 6.5L9 11.5" stroke="white" stroke-width="2" />
        </svg>
      </button>
      <button @click="submitForm" v-if="currentStep === (questionList.length - 1)" class="submit-btn">
        <span v-if="!isLoading">{{ $t('Submit') }}</span>
        <ASpinner v-if="isLoading" :size="19" />
      </button>
    </div>
    <div class="success-message" v-else>
      <StarRating :active-star="ratingStar" />
      <ThankyouMessage />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { registerModule } from '@vue-storefront/core/lib/modules';
import { Feedback } from 'src/modules/feedback';
import StarRating from 'src/modules/feedback/components/StarRating.vue'
import ThankyouMessage from 'src/modules/feedback/components/ThankyouMessage.vue'
import ASpinner from '~/theme/components/atoms/a-spinner';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import i18n from '@vue-storefront/i18n';

export default {
  name: 'DynamicSteps',
  components: {
    StarRating,
    ThankyouMessage,
    ASpinner
  },
  props: {
    orderId: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      formData: {},
      rating: 'rating',
      radio: 'radio',
      radioWithImage: 'radio_with_image',
      inputText: 'input',
      successMessage: '',
      ratingStar: 0,
      isLoading: false,
      radioNumber: '',
      checkbox: 'checkbox',
      isFeedback: true
    }
  },
  computed: {
    ...mapGetters({
      currentStep: 'feedback/getCurrentStep',
      stepPercentage: 'feedback/getStepPercentage',
      questionList: 'feedback/questionList'
    }),
    storeId () {
      const { storeId } = currentStoreView()
      return storeId
    },
    questionSortList () {
      return this.sortQuestionList();
    }
  },
  beforeMount () {
    this.$store.dispatch('feedback/list', { skipCache: true })
  },
  methods: {
    convertString (value) {
      if (value && Array.isArray(value) && value.length > 0) {
        return value.join(',')
      }
      return value
    },
    setCheckbox (event, que) {
      const value = event.target.value // Use value instead of _value
      if (!this.formData[que.id]) {
        this.formData[que.id] = [] // Initialize the array if it doesn't exist
      }
      if (event.target.checked && !this.formData[que.id].includes(value)) {
        this.formData[que.id].push(value) // Add value to the array if checked and not already present
      } else {
        const index = this.formData[que.id].indexOf(value)
        if (index !== -1) {
          this.formData[que.id].splice(index, 1) // Remove value from the array if unchecked
        }
      }
    },
    setFeedBack () {
      this.isFeedback = false;
    },
    nextStep () {
      let step = this.currentStep
      this.$store.dispatch('feedback/updateStep', step + 1)
    },
    prevStep () {
      let step = this.currentStep
      this.$store.dispatch('feedback/updateStep', step - 1)
    },
    async submitForm () {
      this.isLoading = true;
      let response = {
        'review': {
          'item': Object.entries(this.formData).map(([key, value]) => {
            return {
              'order_id': this.orderId,
              'question_id': key,
              'answer': value.toString().includes('#') ? value.split('#')[0] : this.convertString(value),
              'record_id': value.toString().includes('#') ? value.split('#')[1] : 0
            }
          })
        }
      };
      const res = await this.$store.dispatch('feedback/submit', response)
      if (res && res.code === 200) {
        this.ratingStar = response.review.item[0].answer
        this.successMessage = res.result[0].message
        this.formData = {}
        this.isLoading = false
      } else {
        this.successMessage = i18n.t('Feedback submission failed. Please try again.')
        this.isLoading = false
      }
    },
    radioOptions (options) {
      return JSON.parse(options)
    },
    sortQuestionList () {
      return this.questionList.sort((a, b) => parseInt(a.position) - parseInt(b.position));
    }
  },
  beforeCreate () {
    registerModule(Feedback)
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
  * {
    box-sizing: border-box;
  }
  .feedback-block {
    max-width: 38.75rem;
    margin: var(--spacer-20) var(--spacer-15) 0;
    padding:var(--spacer-40);
    border-radius: var(--font-size--xs);
    border: 0.063rem solid #DDD;
    background: var(--c-white);
    @include for-mobile {
      padding: var(--spacer-20);
      margin: var(--spacer-20) auto;
      max-width: 93%;
    }
    .submit-btn {
      position: var(--relative);
      .sf-loader__spinner {
          stroke: var(--c-white);
          position: var(--absolute);
          inset:0;
          margin: auto;
      }
    }
    .progress {
      width: 100%;
      height: var(--spacer-2xs);
      border-radius: var(--spacer-2xs);
      background-color: #F4F4F4;
      position: var(--relative);
      margin-bottom: var(--h1-font-size);
      span {
        position: var(--absolute);
        top: var(--top-0);
        left: var(--left-0);
        height: var(--spacer-2xs);
        width: 0%;
        background-color: var(--c-green);
      }
    }
    .feedback-title {
      font-family: var(--font-family--roboto);
      color: #1F1F1F;
      font-size: 1.75rem;
      font-weight: var(--font-weight--semibold);
      line-height: 1.4;
      text-align: left;
      margin: 0 0 2.813rem 0;
      @include for-mobile {
          font-size: var(--font-size--sm);
          margin-bottom: var(--spacer-20);
      }
    }
    .star-rating {
      display: block;
      i {
        font-size: 3.625rem;
        font-style: normal;
        margin: 0 var(--spacer-10);
        line-height: normal;
        @include for-mobile {
          font-size: 2.375rem;
          margin: 0 0.313rem;
        }
        &.fas {
          color: var(--c-green);
        }
        &.far {
          color: #D9D9D9;
        }
      }
    }
    .star-rating-wrap {
      text-align: left;
    }
    .radio-blocks {
      display: var(--display-flex);
      flex-wrap: wrap;
      .radio {
        margin: 0 var(--spacer-20) var(--spacer-10) var(--spacer-20);
        cursor:var(--pointer);
        input[type=radio] {
          cursor:var(--pointer);
          position: var(--absolute);
          opacity: 0;
          + .radio-label {
            cursor:var(--pointer);
            color: #1F1F1F;
            font-family: var(--font-family--primary);
            font-size: var(--font-size--2xl);
            font-weight: var(--font-weight--semibold);
            line-height: 1;
            @include for-mobile {
              font-size: var(--font-size--sm);
            }
            &:before {
              content: "";
              background: var(--c-white);
              border-radius: 100%;
              border: 0.063rem solid var(--c-green);
              display: inline-block;
              width: 1.25rem;
              height: 1.25rem;
              position: var(--relative);
              margin-right: var(--spacer-10);
              vertical-align: top;
              cursor:var(--pointer);
              text-align: center;
              transition: all 250ms ease;
              @include for-mobile {
                width: 0.938rem;
                height: 0.938rem;
              }
            }
          }
          &:checked {
            + .radio-label {
              &:before {
                 background-color: var(--c-green);
                  box-shadow: inset 0 0 0 3px #f4f4f4;
              }
            }
          }
        }
      }
    }
    .radio-icons {
      display: var(--display-flex);
      @include for-mobile {
        flex-wrap: wrap;
      }
      .radio {
        padding: 0 var(--spacer-xs);
        margin-bottom: var(--spacer-10);
        width: 20%;
        text-align: center;
        @include for-mobile {
          padding: 0 var(--spacer-2xs);
        }
        input[type=radio] {
          cursor:var(--pointer);
          position: var(--absolute);
          opacity: 0;
          + .radio-label {
            cursor:var(--pointer);
            display: inline-block;
            text-align: center;
            img {
              background: var(--c-white);
              border: 0.125rem solid transparent;
              width: var(--spacer-50);
              height: var(--spacer-50);
              position: var(--relative);
              cursor:var(--pointer);
              transition: all 250ms ease;
              border-radius: 50%;
              margin-bottom: var(--spacer-10);
              object-fit: cover;
              max-height: var(--spacer-40);
            }
            span {
              display: block;
              color: #666;
              text-align: center;
              font-family: var(--font-family--primary);
              font-size: 0.813rem;
              font-weight: var(--font-weight--medium);
              line-height: normal;
              word-break: break-word;
            }
          }
          &:checked {
            + .radio-label {
              img {
                border-color: var(--c-green);
              }
              span {
                color: var(--c-green);
              }
            }
          }
        }
      }
      &.radio-no {
        .radio {
          input[type=radio] + .radio-label {
            img {
              max-width: 2.5rem;
              height: 2.5rem;
              width: 2.5rem;
            }
          }
        }
      }
    }
    .checkbox-icons {
      display: var(--display-flex);
      .checkbox {
        cursor:var(--pointer);
        color: #1F1F1F;
        font-family: var(--font-family--primary);
        font-size: var(--font-size--2xl);
        font-weight: var(--font-weight--semibold);
        line-height: 1;
        padding-right: var(--spacer-10);
        @include for-mobile {
          font-size: var(--font-size--base);
        }
      }
      input[type=checkbox] {
          cursor:var(--pointer);
          position: var(--absolute);
          opacity: 0;
          + .checkbox-label {
            cursor:var(--pointer);
            display: inline-block;
            text-align: center;
            position: var(--relative);
            img {
              background: var(--c-white);
              border: 0.125rem solid transparent;
              width: var(--spacer-50);
              height: var(--spacer-50);
              position: var(--relative);
              cursor:var(--pointer);
              transition: all 250ms ease;
              border-radius: 50%;
              margin-bottom: var(--spacer-10);
              object-fit: cover;
            }
            span {
              display: block;
              color: #666;
              text-align: center;
              font-family: var(--font-family--primary);
              font-size: 0.813rem;
              font-weight: var(--font-weight--medium);
              line-height: normal;
            }
          }
          &:checked {
            + .checkbox-label {
              img {
                border-color: var(--c-green);
              }
              span {
                color: var(--c-green);
              }
            }
          }
      }
      input[type=checkbox] + .checkbox-label:before {
        content: '';
        background: var(--c-white);
        border-radius: 0.188rem;
        border: 0.063rem solid var(--c-green);
        display: inline-block;
        width: var(--spacer-20);
        height: var(--spacer-20);
        position: var(--relative);
        margin-right: var(--spacer-10);
        vertical-align: top;
        cursor:var(--pointer);
        text-align: center;
        transition: all 250ms ease;
        @include for-mobile {
          margin-top: -3px;
        }
      }
      input[type=checkbox]:checked + .checkbox-label:after {
        content: '';
        height: 0.875rem;
        border: solid var(--c-green);
        border-width: 0 0.188rem 0.188rem 0;
        transform: rotate(45deg);
        display: flex;
        width: 0.5rem;
        top: 0px;
        left: 0.438rem;
        position: var(--absolute);
        @include for-mobile {
          height: 0.675rem;
          width: 0.4rem;
        }
      }
    }
    .tell-experiance-textarea {
      textarea {
        width: 100%;
        height: 10.625rem;
        border-radius: 0.5rem;
        border: 0.063rem solid #DDD;
        background: var(--c-white);
        padding: var(--spacer-10);
        font-family: var(--font-family--primary);
        font-size: var(--font-size--base);
        resize: none;
      }
    }
    .btns-wrap {
      display: var(--display-flex);
      justify-content: space-between;
      margin-top: var(--spacer-20);
      @include for-mobile {
        margin-top: var(--spacer-30);
      }

      .submit-btn,
      .back-btn,
      .ahead-btn {
        font-family: var(--font-family--primary);
        font-size: var(--font-size--lg);
        font-weight: var(--font-weight--bold);
        line-height: normal;
        line-height: 1.2;
        display: flex;
        width: 100%;
        padding: var(--spacer-20) var(--spacer-45) var(--spacer-20) var(--spacer-50);
        justify-content: center;
        align-items: center;
        gap: var(--spacer-10);
        border-radius: 2.5rem;
        background: var(--c-green);
        color: var(--c-white);
        border: 0;
        cursor:var(--pointer);
        &.disable {
          opacity: 0.5;
          cursor: default;
          pointer-events: none;
        }
        @include for-mobile {
          font-size: var(--font-size--xs);
          padding: var(--spacer-10) var(--spacer-15);
          height: 2.5rem;
        }
        span {
          line-height: 1;
        }
      }
      .back-btn {
        border: 0.063rem solid #DDD;
        background: #F9F9F9;
        color: var(--c-green);
      }
      >.ahead-btn {
        width: 100%;
      }
      .submit-btn {
        width: 48%;
        @include for-mobile {
          width: 100%;
        }
      }
      >.back-btn {
        width: 48%;
        @include for-mobile {
          width: 100%;
          margin-bottom: var(--spacer-10);
          margin-right: 0.25rem;
        }
        +.ahead-btn {
          width: 48%;
          @include for-mobile {
            width: 100%;
            margin-left: 0.25rem;
          }
        }
      }
    }
    .success-message {
      .star-rating {
        pointer-events: none;
      }
      .message-heading {
        color: #1F1F1F;
        font-family: var(--font-family--roboto);
        font-size: 1.75rem;
        font-style: normal;
        font-weight: var(--font-weight--semibold);
        line-height: normal;
        max-width: 16.25rem;
        text-align: center;
        margin: 0 auto;
        margin-top: var(--spacer-30);
        @include for-mobile {
            font-size: var(--font-size--2xl);
            margin-top: var(--spacer-20);
        }
      }
      .message-description {
        color: #666;
        text-align: center;
        font-family: 'Raleway';
        font-size: var(--font-size--base);
        font-style: normal;
        font-weight: var(--font-weight--medium);
        line-height: 1.375rem;
        max-width: 19.813rem;
        margin: 0 auto;
        margin-top: var(--spacer-15);
        @include for-mobile {
          font-size: var(--font-size--sm);
        }
      }
      .social-icons {
        padding-top: var(--spacer-30);
        text-align: center;
        @include for-mobile {
          padding-top: var(--spacer-20);
        }
        a {
          display: inline-block;
          line-height: 0;
          + a {
            margin-left: 16px;
          }
        }
        span {
          display: inline-block;
          &:not(:last-child) {
            margin-right: var(--spacer-15);
          }
        }
        svg {
          width: 2rem;
        }
      }
    }
  }
</style>
