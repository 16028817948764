<template>
  <div class="confirmation">
    <div id="klarna-render-confirmation" />
    <div v-if="confirmation.loading">
      <ASpinner />
    </div>
    <div v-if="confirmation.snippet" v-html="confirmation.snippet" /> <!-- eslint-disable-line vue/no-v-html -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import qs from 'qs'
import { isServer } from '@vue-storefront/core/helpers'
import ASpinner from '~/theme/components/atoms/a-spinner.vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import config from 'config';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { Logger } from '@vue-storefront/core/lib/logger';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';

export default {
  name: 'KlarnaConfirmation',
  computed: {
    ...mapGetters({
      confirmation: 'kco/confirmation'
    })
  },
  components: {
    ASpinner
  },
  beforeMount () {
    if (!this.$route.query.merchantReference) {
      this.$router.push(localizedRoute('/'))
    }
  },
  async mounted () {
    if (!isServer) {
      await this.renderOrderSnippet();
      await this.sendTrackingData();
    }
  },
  data () {
    return {
      areAnalyticEventsSent: false
    }
  },
  methods: {
    clearCart () {
      this.$store.dispatch('cart/clear')
    },
    async renderOrderSnippet () {
      const queryString = this.$route.fullPath.replace(this.$route.path, '')
      const { merchantReference } = qs.parse(queryString, { ignoreQueryPrefix: true })
      if (!merchantReference) {
        return
      }

      this.$bus.$emit('kco-order-confirmation', { orderId: merchantReference })
      await this.$store.dispatch('kco/confirmation', { sid: merchantReference })

      const { default: postscribe } = await import('postscribe')

      postscribe('#klarna-render-confirmation', this.confirmation.snippet)

      this.clearCart();
    },
    shouldAnalyticsEventsBeSent (order) {
      return (order?.gtm_event_sent === false || order?.gtm_event_sent === '0') &&
          this.areAnalyticEventsSent === false;
    },
    async storeOrderGtmEventSent (orderId) {
      try {
        const apiUrl = processLocalizedURLAddress(getApiEndpointUrl(config.checkout, 'order_gtm_event_sent_endpoint'))
          .replace('{{orderId}}', orderId)

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const res = ((await response.json()).result)
        if (res) {
          res.status === 'success' && Logger.info('Order GTM event sent!', 'checkout-confirmation', res)()
          return res.status === 'success'
        }
        throw new Error(`Cannot store gtm event sent for order entity id: ${orderId}`)
      } catch (err) {
        throw new Error(err)
      }
    },
    async sendTrackingData () {
      if (!this.confirmation) {
        return;
      }

      const orderData = await this.$store.dispatch('kco/getTrackingData', { token: this.confirmation.order_id })
      const response = orderData[0]

      if (response.error) {
        console.error('ERROR', response.error);
      }

      if (response.order && parseInt(response.order.entity_id)) {
        if (this.shouldAnalyticsEventsBeSent(response.order)) {
          const areAnalyticEventsSentStored = await this.storeOrderGtmEventSent(response.order.entity_id)
          if (!areAnalyticEventsSentStored) {
            throw new Error('Cannot store gtm_event_sent to true')
          }

          this.areAnalyticEventsSent = true
          this.$bus.$emit('checkout-confirmed', response.order)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation {
  min-height: 580px;
}
</style>

<style lang="scss">
#klarna-unsupported-page {
  display: none;
}
</style>
