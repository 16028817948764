<template>
  <div id="checkout-confirmation">
    <div class="checkout">
      <div class="sf-heading">
        <h3 class="h3">
          <label>
            <svg
              v-if="!order"
              width="102"
              height="104"
              viewBox="0 0 102 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.0639 4.13012C49.8151 2.85961 52.1849 2.85961 53.9361 4.13012L59.0644 7.85061C60.1673 8.65077 61.549 8.96614 62.8899 8.72376L69.1246 7.59679C71.2537 7.21194 73.3887 8.24013 74.4153 10.1447L77.4215 15.7218C78.068 16.9212 79.1761 17.8049 80.4893 18.1683L86.5955 19.8581C88.6807 20.4351 90.1583 22.2878 90.2568 24.4492L90.5455 30.7783C90.6075 32.1395 91.2225 33.4164 92.248 34.3136L97.0163 38.4854C98.6447 39.9101 99.172 42.2204 98.323 44.2105L95.837 50.0381C95.3023 51.2914 95.3023 52.7086 95.837 53.9619L98.323 59.7895C99.172 61.7796 98.6447 64.0899 97.0163 65.5146L92.248 69.6864C91.2225 70.5836 90.6075 71.8605 90.5455 73.2217L90.2568 79.5508C90.1583 81.7122 88.6808 83.5649 86.5955 84.1419L80.4893 85.8317C79.1761 86.1951 78.068 87.0788 77.4215 88.2782L74.4153 93.8553C73.3887 95.7599 71.2537 96.7881 69.1246 96.4032L62.8899 95.2762C61.549 95.0339 60.1673 95.3492 59.0644 96.1494L53.9361 99.8699C52.1849 101.14 49.8151 101.14 48.0639 99.8699L42.9356 96.1494C41.8327 95.3492 40.451 95.0339 39.1101 95.2762L32.8754 96.4032C30.7463 96.7881 28.6112 95.7599 27.5847 93.8553L24.5785 88.2782C23.932 87.0788 22.8239 86.1951 21.5107 85.8317L15.4045 84.1419C13.3193 83.5649 11.8417 81.7122 11.7432 79.5508L11.4545 73.2217C11.3925 71.8605 10.7775 70.5836 9.75203 69.6864L4.98367 65.5146C3.35532 64.0899 2.828 61.7796 3.67697 59.7895L6.16303 53.9619C6.6977 52.7086 6.6977 51.2914 6.16303 50.0381L3.67697 44.2105C2.828 42.2204 3.35532 39.9101 4.98366 38.4854L9.75203 34.3136C10.7775 33.4164 11.3925 32.1395 11.4545 30.7783L11.7432 24.4492C11.8417 22.2878 13.3192 20.4351 15.4045 19.8581L21.5107 18.1683C22.8239 17.8049 23.932 16.9212 24.5785 15.7218L27.5847 10.1447C28.6112 8.24013 30.7463 7.21194 32.8754 7.59679L39.1101 8.72376C40.451 8.96614 41.8327 8.65077 42.9356 7.85061L48.0639 4.13012Z"
                fill="#FDB91E"
                fill-opacity="0.08"
                stroke="#FDB91E"
                stroke-width="5"
              />
              <g clip-path="url(#clip0_506_653)">
                <path d="M68.6328 62.5301L54.6647 38.362C53.6752 36.6573 51.2923 36.5064 50.2655 38.1726L36.4093 62.493C35.3095 64.273 36.5553 67 38.6089 67H66.3973C68.4122 67 69.6596 64.3103 68.6328 62.5301ZM52.4296 62.9089C51.44 62.9089 50.6338 62.0759 50.6338 61.0534C50.6338 60.0309 51.44 59.1979 52.4296 59.1979C53.4191 59.1979 54.2253 60.0309 54.2253 61.0534C54.2253 62.0759 53.4191 62.9089 52.4296 62.9089ZM54.3355 56.0904C54.3355 57.1129 53.4922 57.946 52.5026 57.946C51.513 57.946 50.6696 57.1129 50.6696 56.0904V46.3938C50.6696 45.3713 51.513 44.5382 52.5026 44.5382C53.4922 44.5382 54.3355 45.3713 54.3355 46.3938V56.0904Z" fill="#FDB91E" />
              </g>
              <defs>
                <clipPath id="clip0_506_653">
                  <rect
                    width="33"
                    height="30"
                    fill="white"
                    transform="translate(36 37)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              v-else
              width="102"
              height="104"
              viewBox="0 0 102 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.0639 4.13012C49.8151 2.85961 52.1849 2.85961 53.9361 4.13012L59.0644 7.85061C60.1673 8.65077 61.549 8.96614 62.8899 8.72376L69.1246 7.59679C71.2537 7.21194 73.3887 8.24013 74.4153 10.1447L77.4215 15.7218C78.068 16.9212 79.1761 17.8049 80.4893 18.1683L86.5955 19.8581C88.6807 20.4351 90.1583 22.2878 90.2568 24.4492L90.5455 30.7783C90.6075 32.1395 91.2225 33.4164 92.248 34.3136L97.0163 38.4854C98.6447 39.9101 99.172 42.2204 98.323 44.2105L95.837 50.0381C95.3023 51.2914 95.3023 52.7086 95.837 53.9619L98.323 59.7895C99.172 61.7796 98.6447 64.0899 97.0163 65.5146L92.248 69.6864C91.2225 70.5836 90.6075 71.8605 90.5455 73.2217L90.2568 79.5508C90.1583 81.7122 88.6808 83.5649 86.5955 84.1419L80.4893 85.8317C79.1761 86.1951 78.068 87.0788 77.4215 88.2782L74.4153 93.8553C73.3887 95.7599 71.2537 96.7881 69.1246 96.4032L62.8899 95.2762C61.549 95.0339 60.1673 95.3492 59.0644 96.1494L53.9361 99.8699C52.1849 101.14 49.8151 101.14 48.0639 99.8699L42.9356 96.1494C41.8327 95.3492 40.451 95.0339 39.1101 95.2762L32.8754 96.4032C30.7463 96.7881 28.6112 95.7599 27.5847 93.8553L24.5785 88.2782C23.932 87.0788 22.8239 86.1951 21.5107 85.8317L15.4045 84.1419C13.3193 83.5649 11.8417 81.7122 11.7432 79.5508L11.4545 73.2217C11.3925 71.8605 10.7775 70.5836 9.75203 69.6864L4.98367 65.5146C3.35532 64.0899 2.828 61.7796 3.67697 59.7895L6.16303 53.9619C6.6977 52.7086 6.6977 51.2914 6.16303 50.0381L3.67697 44.2105C2.828 42.2204 3.35532 39.9101 4.98366 38.4854L9.75203 34.3136C10.7775 33.4164 11.3925 32.1395 11.4545 30.7783L11.7432 24.4492C11.8417 22.2878 13.3192 20.4351 15.4045 19.8581L21.5107 18.1683C22.8239 17.8049 23.932 16.9212 24.5785 15.7218L27.5847 10.1447C28.6112 8.24013 30.7463 7.21194 32.8754 7.59679L39.1101 8.72376C40.451 8.96614 41.8327 8.65077 42.9356 7.85061L48.0639 4.13012Z"
                fill="#609a7f"
                fill-opacity="0.08"
                stroke="#609a7f"
                stroke-width="5"
              />
              <path
                d="M33.915 55.1915L45.681 65.8298L69.0853 40.2979"
                stroke="#609a7f"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </label>
          {{ areAnalyticEventsSent ? $t('Thank you for your purchase!') : $t('Please, wait for the purchase confirmation') }}
        </h3>
        <transition name="fade">
          <div v-if="order" class="description">
            <span>
              {{ $t('Your purchase with order number') }} <b>#{{ order.increment_id }}</b> {{ $t('is complete.') }}
            </span>
          </div>
        </transition>
      </div>
      <transition name="fade">
        <div v-if="order" class="checkout__completed">
          <div class="completed-column-box">
            <div class="dynamic-title">
              <h2>{{ $t('Help Us Improve Your Shopping Experience!') }}</h2>
            </div>
            <div class="dynamic-steps">
              <DynamicSteps :order-id="order.increment_id" />
              <div class="completed-column-row">
                <div class="form-group">
                  <div class="form-label">
                    {{ $t('Payment method') }}
                  </div>
                  <div class="form-output" id="completed-card--output-purchase-method">
                    {{ order.payment_name }}
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-label">
                    {{ $t('Order confirmation is sent to') }}
                  </div>
                  <div class="form-output" id="completed-card--output-email">
                    {{ order.customer_email }}
                  </div>
                </div>
                <div class="form-group" id="completed-card--group-purchase-identifier">
                  <div class="form-label">
                    {{ $t('Shipping address') }}
                  </div>
                  <div class="form-output" id="completed-card--output-purchase-identifier">
                    {{ order.firstname }} {{ order.lastname }}
                    <div class="address">
                      {{ getOrderShippingAddress(order) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="completed-column-bottom">
            <div class="completed-left">
              <OFooterFaq />
            </div>
          </div>
        </div>
        <div v-else class="checkout__loading">
          <ASpinner />
        </div>
      </transition>
    </div>
    <div id="trustedShopsCheckout" style="display: none;" v-if="order">
      <span id="tsCheckoutOrderNr">{{ order.increment_id }}</span>
      <span id="tsCheckoutBuyerEmail">{{ order.customer_email }}</span>
      <span id="tsCheckoutOrderAmount">{{ order.grand_total }}</span>
      <span id="tsCheckoutOrderCurrency">{{ order.base_currency_code }}</span>
      <span id="tsCheckoutOrderPaymentType">{{ order.payment_name }}</span>
      <span class="tsCheckoutProductItem" v-for="(item, index) in order.items" :key="index">
        <span class="tsCheckoutProductUrl" v-if="item.url">{{ item.url }}</span>
        <span class="tsCheckoutProductImageUrl" v-if="item.image">{{ item.image }}</span>
        <span class="tsCheckoutProductName">{{ item.item_name }}</span>
        <span class="tsCheckoutProductSKU">{{ item.item_id }}</span>
        <span class="tsCheckoutProductPrice">{{ item.price }}</span>
        <span class="tsCheckoutProductDiscount">{{ item.discount }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import config from 'config';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import i18n from '@vue-storefront/i18n';
import { Logger } from '@vue-storefront/core/lib/logger';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import ASpinner from '~/theme/components/atoms/a-spinner.vue';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import OFooterFaq from '~/theme/components/organisms/o-footer-faq';
import { formatPrice } from '~/theme/helpers';
import DynamicSteps from 'src/modules/feedback/components/DynamicSteps.vue';

export default {
  name: 'CheckoutConfirmation',
  components: {
    ASpinner,
    OFooterFaq,
    DynamicSteps
  },
  data () {
    return {
      poll: null,
      timeout: null,
      order: null,
      areAnalyticEventsSent: false
    }
  },
  beforeMount () {
    if (!this.$route.query.reference) {
      this.$router.push(localizedRoute('/'))
    }
    this.$nextTick(() => {
      this.bootstrap()
    })
    Array.from(document.querySelectorAll('[id^="trustbadge-container"]')).map((item) => { item.classList.remove('trustbadge-hide'); });
  },
  beforeDestroy () {
    this.$nextTick(() => {
      this.clear()
    })
  },
  methods: {
    formatPrice (price) {
      return formatPrice(price)
    },
    orderShippingAmount (order) {
      return this.formatPrice(parseFloat(order.shipping_amount) + parseFloat(order.shipping_tax_amount))
    },
    orderDiscountAmount (amount) {
      return this.formatPrice(parseFloat(amount.replace('-', '')))
    },
    getOrderShippingAddress (order) {
      let addressArray = [order.street, order.city, order.region, order.postcode, order.country_id];
      let filtered = addressArray.filter((el) => { return el != null; });
      return filtered.join(', ')
    },
    shouldAnalyticsEventsBeSent (order) {
      return (order?.gtm_event_sent === false || order?.gtm_event_sent === '0') &&
        this.areAnalyticEventsSent === false;
    },
    async fetchPaymentStatus () {
      let order = null
      try {
        const stringifiedOrder = await this.fetchOrderInformation()
        order = JSON.parse(stringifiedOrder)
        if (this.shouldAnalyticsEventsBeSent(order)) {
          const areAnalyticEventsSentStored = await this.storeOrderGtmEventSent(order.entity_id)
          if (!areAnalyticEventsSentStored) {
            throw new Error('Cannot store gtm_event_sent to true')
          }

          this.areAnalyticEventsSent = true
          this.$bus.$emit('checkout-confirmed', order)
        }
        if (order?.state) {
          Logger.info('Clearing cart for order state: processing', 'checkout-confirmation', { order })()
          this.clear()
        }
        this.order = order
      } catch (err) {
        Logger.error(err?.message || err, 'checkout-confirmation', { order })()
      } finally {
        this.$nextTick(() => {
          this.clearCart()
        })
      }
    },
    async storeOrderGtmEventSent (orderId) {
      try {
        const apiUrl = processLocalizedURLAddress(getApiEndpointUrl(config.checkout, 'order_gtm_event_sent_endpoint'))
          .replace('{{orderId}}', orderId)
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        const res = ((await response.json()).result)
        if (res) {
          res.status === 'success' && Logger.info('Order GTM event sent!', 'checkout-confirmation', res)()
          return res.status === 'success'
        }
        throw new Error(`Cannot store gtm event sent for order entity id: ${orderId}`)
      } catch (err) {
        throw new Error(err)
      }
    },
    async fetchOrderInformation () {
      try {
        // TODO hide behind useCollector
        const apiUrl = processLocalizedURLAddress(getApiEndpointUrl(config.checkout, 'collector_payment_status_endpoint'))
          .replace('{{publicToken}}', this.$route.query.reference)
        const response = await fetch(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          }
        })
        const { order } = ((await response.json()).result) || {}
        if (order) {
          return order
        }
        throw new Error('Cannot get order')
      } catch (err) {
        throw new Error(err)
      }
    },
    bootstrap () {
      if (!this.interval) {
        this.poll = setInterval(() => {
          this.fetchPaymentStatus()
        }, config.checkout.collector_payment_status_poll_interval * 1000);
      }
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          this.clear()
        }, config.checkout.collector_payment_status_timeout * 1000);
      }
    },
    clearCart () {
      this.$store.dispatch('cart/clear')
    },
    clear () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.poll) {
        clearInterval(this.poll)
      }
    },
    getProductUrl (item) {
      return `${window.location.hostname}/${formatProductLink(item, currentStoreView().storeCode)}`
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('Checkout confirmation'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";

#checkout-confirmation {
  padding: var(--spacer-2xl) 0 var(--spacer-40) 0;
  min-height: calc(100vh - 36.4rem);
  @include for-desktop {
    min-height: calc(100vh - 29.8rem);
    max-width: var(--container-width);
    margin: 0 auto;
  }
  @include for-mobile {
    padding: var(--spacer-20) 0 var(--spacer-10) 0;
  }
  .sf-heading {
    margin-bottom: var(--spacer-60);
    @include for-mobile {
      margin-bottom: var(--spacer-base);
      padding: 0 var(--spacer-sm);
    }
    h3 {
      color: var(--c-primary);
      font-family: var(--font-family--primary);
      font-size: var(--h1-font-size);
      font-weight: var(--font-weight--medium);
      line-height: normal;
      margin-bottom: var(--spacer-sm);
      @include for-mobile {
        font-size: var(--h1-font-size);
      }
      label {
        display: block;
        line-height: 0;
        text-align: center;
        margin-bottom: var(--spacer-50);
        @include for-mobile {
          margin-bottom: var(--spacer-25);
          svg {
            width: 75px;
            height: auto;
          }
        }
      }
    }
  }
  .description {
    color: var(--c-text-secondary);
    font-family: var(--font-family--primary);
    font-size: var(--font-size--xl);
    font-weight: var(--font-weight--medium);
    line-height: var(--line-height-32);
    max-width: 825px;
    margin: auto;
    @include for-mobile {
      font-size: var(--font-size--base);
      line-height: var(--line-height-24);
    }
    b {
      color: var(--c-success);
      font-weight: var(--font-weight--bold);
    }
  }
  .completed-column-box {
    text-align: center;
    margin-bottom: var(--spacer-2xl);
    @include for-mobile {
      margin-bottom: var(--spacer-30);
    }
    .line {
      text-align: center;
      line-height: 0;
      svg {
        max-width: 100%;
      }
    }
    .dynamic-steps {
      @include for-desktop {
        display: flex;
        justify-content: space-between;
      }
      box-sizing: border-box;
      .feedback-block {
        @include for-desktop {
          width: 48%;
        }
        @include for-mobile {
          width: 100%;
          padding: var(--spacer-20);
        }
        box-sizing: border-box;
        max-width: 100%;
      }
    }
    .completed-column-row {
      padding: var(--spacer-40) 0;
      @include for-desktop {
          width: 48%;
      }
      box-sizing: border-box;
      @include for-mobile {
        padding: var(--spacer-sm) 0;
        width: 100%;
        border: solid 1px var(--c-light);
        margin-top: var(--spacer-30);
      }
      @media (max-width: 767px) {
        display: block;
      }
      .form-group {
        text-align: center;
        @include for-desktop {
         border-top: solid 1px var(--c-light);
        }
        border-bottom: solid 1px var(--c-light);
        margin-bottom: -1px;
        padding:var( --font-size--xl) var(--spacer-sm);
        @media (max-width: 767px) {
          width: 100%;
          padding:var(--spacer-sm) 0px;
          &:last-child {
            border-bottom:0;
          }
        }
        .form-label {
          color: var(--c-text-secondary);
          font-family: var(--font-family--primary);
          font-size: var(--font-size--lg);
          font-weight: var(--font-weight--medium);
          line-height: normal;
          margin-bottom: var(--spacer-10);
          @include for-mobile {
            font-size: var(--font-size--base);
            margin-bottom: var(--spacer-xs);
          }
        }
        .form-output {
          font-family: var(--font-family--primary);
          color: var(--c-primary);
          font-size: var(--font-size--xl);
          font-weight: var(--font-weight--semibold);
          line-height: normal;
          @include for-mobile {
            font-size: var(--font-size--base);
          }
          .address {
            font-weight: normal
          }
        }
      }
    }
  }
}
::v-deep .checkout {
  @include for-desktop {
    display: flex;
    flex-direction: column;
  }
  &__loading {
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/variables";
  #checkout-confirmation {
    .dynamic-title {
      text-align: left;
      font-family: var(--font-family--primary);
      margin: 0 0 var(--spacer-30) 0;
      @include for-mobile {
          padding: 0px var(--spacer-sm);
        }
      h2 {
        margin: 0 0 var(--spacer-10) 0;
        @include for-mobile {
          font-size: var(--h4-font-size);
        }
      }
      p {
        color: var(--c-text-secondary);
        font-family: var(--font-family--primary);
        font-size: var(--font-size--lg);
        font-weight: var(--font-weight--medium);
        line-height: normal;
        margin:0;
        @include for-mobile {
          font-size: var(--font-size--base);
        }
      }
    }
    .dynamic-steps {
      @include for-mobile {
        padding: 0px var(--spacer-sm);
      }
      .feedback-block {
        .btns-wrap .next-btn {
            font-size: var(--font-size--base);
            @include for-mobile {
              padding: 10px 18px;
            }
        }
        .feedback-title, .message-heading {
          font-family: var(--font-family--primary);
        }
        .progress {
          @include for-mobile {
            margin-bottom: var(--spacer-20);
          }
        }
        .radio-blocks .radio {
          margin-left: var(--spacer-xs);
          margin-right:  var(--spacer-xs);
        }
      }
    }
    .completed-column-bottom {
      display: flex;
      justify-content: center;
      @include for-mobile {
        flex-direction: column;
        padding:0 var(--spacer-sm);
      }
      .completed-left {
        width: 60%;
        @include for-mobile {
          width: 100%;
          order: 2;
        }
        .footer-toggle-wrap {
          padding:0 var(--spacer-30) var(--spacer-10) var(--spacer-30);
          border: 1px solid var(--c-light);
          background: var(--c-light-gray-variant);
          border-top:0;
          @include for-mobile {
            padding: 0 var(--spacer-20) var(--spacer-10) var(--spacer-20);
          }
          h2 {
            margin: 0 var(--spacer-31-minus) var(--spacer-xs) var(--spacer-31-minus);
            padding: 0 0 var(--spacer-25) 0px;
            text-align: center !important;
            color: var(--c-primary);
            font-family: var(--font-family--primary);
            font-size: var(--font-size--2xl);
            font-weight: var(--font-weight--semibold);
            line-height: normal;
            background: var(--c-white);
            border-bottom: 1px solid var(--c-light);
            @include for-mobile {
                margin: 0 var(--spacer-21-minus) var(--spacer-xs) var(--spacer-21-minus);
                font-size: var(--h4-font-size);
            }
          }
          .toggle-question {
            padding:var(--spacer-25) var(--spacer-25) var(--spacer-25) 0;
            margin: 0;
            color: var(--c-primary);
            font-family: var(--font-family--primary);
            font-size: var(--font-size--lg);
            line-height: normal;
            position: relative;
            cursor: pointer;
            font-weight: var(--font-weight--medium);
            @include for-mobile {
              padding: var(--spacer-sm) 23px var(--spacer-sm) 0;
              font-size: var(--font-size--sm);
            }
            &:before {
              content: '';
              position: absolute;
              right: 0;
              top: 33px;
              font-size: var(--font-size--2xl);
              -webkit-transition: 0.2s;
              -o-transition: 0.2s;
              transition: 0.2s;
              background: var(--c-primary);
              line-height: var(--line-height-15);
              width: 18px;
              height: 1.5px;
              @include for-mobile {
                top: 24px;
              }
            }
            &::after {
              content: '';
              position: absolute;
              right: 8px;
              top: 25px;
              font-size: var(--font-size--2xl);
              transition: 0.2s;
              background: var(--c-primary);
              line-height: var(--line-height-15);
              width: 2px;
              height: 18px;
              @include for-mobile {
                top: 16px;
              }
            }
            &.active{
              &::after {
                display: none;
              }
            }
          }
          .toggle-answer {
              transition: .5s ease all;
              max-height: 0;
              overflow: hidden;
              line-height: var(--line-height-24);
              margin: 0 0 0;
              border-bottom: 1px solid var(--c-light);
              color: var(--c-text-secondary);
              font-family: var(--font-family--primary);
              font-size: var(--font-size--base);
              font-weight: var(--font-weight--normal);
              @include for-mobile {
                line-height: var(--line-height-22);
                font-size: var(--font-size--sm);
              }
              &:last-of-type {
                border: 0;
              }
          }
          .toggle-question.active + .toggle-answer {
            max-height: 2000px;
            padding-bottom: var(--spacer-25);
          }
        }
      }
      .completed-right {
        width: 48%;
        @include for-mobile {
          width: 100%;
          padding-bottom: var(--spacer-25);
        }
        h2 {
          margin: 0 0 var(--spacer-25) 0;
          color: var(--c-black-1F1F1F);
          font-family: var(--font-family--roboto);
          font-size: var(--font-size--2xl);
          font-weight: var(--font-weight--semibold);
          line-height: normal;
          background: var(--c-white);
        }
        table {
          border: solid 1px var(--c-light);
          width: 100%;
          border-collapse: collapse;
          tr {
            td {
              border-top: 1px solid var(--c-light);
              padding:var(--spacer-20) var(--spacer-20);
              @include for-mobile {
                padding: var(--spacer-xs) var(--spacer-10);
              }
              &:last-child {
                width: 100px;
                border-left: 1px solid var(--c-light);
                @include for-mobile {
                  width: 64px;
                }
              }
            }
            &.total {
              background: var(--c-light-gray-variant);
              td {
                color: var(--c-primary);
                font-size: var(--font-size--base);
                font-weight: var(--font-weight--semibold);
                line-height: normal;
                text-align: right;
                @include for-mobile {
                  font-size: var(--font-size--sm);
                }
                &:last-child {
                  font-family: var(--font-family--roboto);
                  text-align: left;
                }
              }
            }
            &.price-label {
              td {
                border-top: 0;
                color: var(--c-text-secondary);
                padding: var(--spacer-10) var(--spacer-20);
                @include for-mobile {
                  padding: var(--spacer-xs) var(--spacer-10);
                }
                &:first-child {
                  text-align: right;
                }
                &:last-child {
                  font-family: var(--font-family--roboto);
                }
              }
            }
            &.product-label {
              td {
                border-bottom: solid 1px var(--c-light);
              }
            }
            .item-product {
              display: flex;
              border: 0;
              .item-image {
                min-width: 80px;
                background: var(--c-gray-FBFAF9);
                min-height: 80px;
                @include for-mobile {
                  min-height: 60px;
                }
              }
              .item-disc{
                padding-left: var(--spacer-15);
                @include for-mobile {
                  padding-left:var(--spacer-10);
                }
                span {
                  display: block;
                }
                .item-name {
                  color: var(--c-primary);
                  font-family: var(--font-family--primary);
                  font-size: var(--font-size--base);
                  font-weight: 600;
                  line-height: var(--line-height-22);
                  margin-bottom: var(--spacer-15);
                }
                .item-quantity {
                  color: var(--c-primary);
                  font-family: var(--font-family--primary);
                  font-size: var(--font-size--sm);
                  font-style: normal;
                  font-weight: var(--font-weight--normal);
                  line-height: normal;
                }
              }
            }
            .item-price {
              color: var(--c-primary);
              font-family: var(--font-family--roboto);
              font-size: var(--font-size--base);
              font-weight: var(--font-weight--medium);
              vertical-align: top;
              @include for-mobile {
                font-size: var(--font-size--sm);
              }
            }
          }
        }
      }
    }
  }
</style>
