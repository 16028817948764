<template>
  <div class="star-rating">
    <span
      v-for="star in totalStars"
      :key="star"
      @mouseover="hoverRating(star)"
      @click="setRating(star)"
      @mouseleave="resetHover"
    >
      <i :class="{ 'fas': star <= hoverValue, 'far': star > hoverValue }">&#9733;</i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    value: {
      type: Number,
      default: 0
    },
    maxStars: {
      type: Number,
      default: 5
    },
    activeStar: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      hoverValue: this.activeStar
    };
  },
  computed: {
    totalStars () {
      return Array.from({ length: this.maxStars }, (_, index) => index + 1);
    }
  },
  methods: {
    hoverRating (value) {
      this.hoverValue = value;
    },
    setRating (value) {
      this.$emit('input', value);
      this.$emit('feedback', true);
    },
    resetHover () {
      this.hoverValue = this.value;
    }
  }
};
</script>

<style scoped>
.star-rating {
  display: inline-block;
}

.star-rating span {
  cursor: pointer;
  display: inline-block; /* Added to prevent the hover effect from affecting previous stars */
}

.star-rating i {
  transition: color 0.3s ease-in-out; /* Added transition for a smoother effect */
}

.fas {
  color: #ffc107; /* or your preferred filled star color */
}

.far {
  color: #ddd; /* or your preferred empty star color */
}
</style>
